<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="warning" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('offers.aboutTheOffer') }}</span>
				<v-spacer />
				<v-btn icon @click="switchEditMode()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-row>
						<v-col cols="12" class="pt-1 pb-0">
							<v-text-field
								v-if="offer.category.external"
								v-model="externalLink"
								:label="$t('offers.externalLink')"
								:hint="$t('offers.externalLinkHelp')"
								:rules="rules.externalLink"
								prepend-inner-icon="mdi-earth"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="12" class="pt-1 pb-0">
							<v-text-field v-model="title" :label="$t('newsfeed.title')" :rules="rules.title" outlined rounded hide-details="auto" class="pb-3" />
						</v-col>
						<v-col cols="12" class="pt-1 pb-0">
							<v-select
								v-model="offerType"
								:label="$t('offers.offerType')"
								:items="availableOfferTypes"
								item-text="name"
								item-value="key"
								:rules="rules.offerType"
								return-object
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="6" class="pt-1 pb-0">
							<v-select
								v-model="fulltime"
								:label="$t('offers.fullParttime')"
								:items="fulltimeOptions"
								item-text="name"
								item-value="key"
								:rules="rules.fulltime"
								return-object
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="6" class="pt-1 pb-0">
							<v-text-field
								v-model="startingDate"
								:label="$t('offers.start')"
								:rules="rules.startingDate"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="6" class="pt-1 pb-0">
							<v-text-field
								v-model="duration"
								:label="$t('offers.duration')"
								:rules="rules.duration"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="6" class="pt-1 pb-0">
							<v-text-field
								v-model="location.city"
								:label="$t('invoices.city')"
								:rules="rules.location.city"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="6" class="pt-1 pb-0">
							<v-autocomplete
								v-model="location.province"
								:label="$t('invoices.province')"
								:items="availableSpainProvinces"
								:rules="rules.location.province"
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-col cols="6" class="pt-1 pb-0">
							<v-autocomplete
								v-model="location.country"
								:label="$t('invoices.country')"
								:items="availableCountries"
								item-text="name"
								item-value="key"
								:rules="rules.location.country"
								return-object
								outlined
								rounded
								hide-details="auto"
								class="pb-3"
							/>
						</v-col>
						<v-textarea
							v-model="description"
							:label="$t('courses.description')"
							:counter="descriptionMaxLength"
							:rules="rules.description"
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							auto-grow
						/>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text rounded plain @click="callEdit()">{{ $t('settings.edit') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'OfferAboutUpdateForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			externalLink: '',
			title: '',
			location: {
				city: '',
				province: '',
				country: null
			},
			description: '',
			startingDate: '',
			duration: '',
			offerType: null,
			fulltime: null,
			fulltimeOptions: [
				{ key: 0, name: this.$t('offers.parttime') },
				{ key: 1, name: this.$t('offers.fulltime') }
			],
			rules: {
				externalLink: [
					(v) => (!!v && this.offer.category.external) || this.$t('rules.required'),
					(v) =>
						(v && this.offer.category.external && v.length <= this.externalLinkMaxLength) ||
						this.$t('rules.length', { length: this.externalLinkMaxLength })
				],
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength })
				],
				location: {
					city: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.cityMaxLength) || this.$t('rules.length', { length: this.cityMaxLength })
					],
					province: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && this.availableSpainProvinces.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
					],
					country: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && this.availableCountries.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
					]
				},
				startingDate: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.startingDateMaxLength) || this.$t('rules.length', { length: this.startingDateMaxLength })
				],
				duration: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.durationMaxLength) || this.$t('rules.length', { length: this.durationMaxLength })
				],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				],
				offerType: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableOfferTypes.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				fulltime: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.fulltimeOptions.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				]
			}
		}
	},
	computed: {
		externalLinkMaxLength() {
			return 1024
		},
		titleMaxLength() {
			return 255
		},
		startingDateMaxLength() {
			return 64
		},
		durationMaxLength() {
			return 64
		},
		cityMaxLength() {
			return 128
		},
		descriptionMaxLength() {
			return 1024 * 4
		},
		...mapGetters({
			offer: 'offers/offer',
			availableOfferTypes: 'data/availableOfferTypes',
			availableCountries: 'data/availableCountries',
			availableSpainProvinces: 'data/availableSpainProvinces'
		})
	},
	created() {
		this.fetchAvailableOfferTypes()
		this.fetchAvailableCountries()
		this.fetchAvailableSpainProvinces()
		this.externalLink = this.offer.externalLink
		this.title = this.offer.title
		this.location = this.offer.info.location
		this.description = this.offer.info.description
		this.startingDate = this.offer.info.startingDate
		this.duration = this.offer.info.duration
		this.offerType = this.offer.info.type
		this.fulltime = this.offer.info.time
		this.loading = false
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callEdit() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editOffer({
				id: this.offer.id,
				externalLink: this.externalLink,
				title: this.title,
				city: this.location.city,
				province: this.location.province,
				country: this.location.country,
				description: this.description,
				startingDate: this.startingDate,
				duration: this.duration,
				offerType: this.offerType,
				fulltime: this.fulltime.key,
				thesisOptions: this.offer.info.thesisOptions,
				salary: this.offer.info.salary,
				benefits: this.offer.info.benefits,
				sectors: this.offer.info.sectors,
				qualifications: this.offer.info.qualifications
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('offers', ['editOffer']),
		...mapActions('data', ['fetchAvailableOfferTypes', 'fetchAvailableCountries', 'fetchAvailableSpainProvinces'])
	}
}
</script>
